import React from 'react'
import { FormattedMessage } from "gatsby-plugin-intl"
import '../scss/leadItem.scss';
import MoreLink from './moreLink';
import PriceTag from './priceTag';


const LeadItem = ({ intl, icon, title, body, link, price }) => {
	return (
		<div className="col col--3 leadItem">
			<h2 className="title title--icon"><img className="icon" src={icon} style={{height: "2.5rem"}} /><span>{title}</span></h2>
			{body.map(paragraph => <p>
				<FormattedMessage
				id={paragraph}
				defaultMessage={paragraph}
				values={{
					b: chunks => <b>{chunks}</b>
				}}
			/>
				</p>)}
				<span style={{display: 'flex', justifyContent: "space-between"}}>
					{link&&<p><MoreLink to={link} /></p>}
					{price&&<p><PriceTag price={price} /></p>}
				</span>
		</div>
	)
}

export default LeadItem
