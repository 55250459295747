import React from "react"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import treeIcon from "../images/tree-icon.png"
import jeepIcon from "../images/jeep-icon.png"
import mountainIcon from "../images/mountain-icon.png"

import trekking from "../images/trekking.png"
import trip1 from "../images/trip1/dziki7.jpg"
import trip2 from "../images/trip2/wschod8.jpg"
import trip3 from "../images/trip3/lewada6.jpg"
import trip4 from "../images/trip4/biegun6.jpg"
import trip5 from "../images/trip5/hawaje1.jpeg"

import taExcelPl from "../images/ta-excel-pl.png"
import taExcelEn from "../images/ta-excel-en.png"
import taInfo from "../images/ta-info-clean.png"

import LeadItem from "../components/leadItem"
import TripItem from "../components/tripItem"
import MoreLink from "../components/moreLink"

const IndexPage = ({ intl }) => (
  (intl.locale = "pl"),
  (
    <Layout pageName="home">
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "title" })}
        description={intl.formatMessage({ id: "description" })}
        keywords={[`gatsby`, `application`, `react`]}
      />

      <section className="wrapper">
        <div className="overlap container index">
          <LeadItem
            title={intl.formatMessage({ id: "index.lead_1_title" })}
            body={[intl.formatMessage({ id: "index.lead_1_p1" })]}
            icon={treeIcon}
            link="/about/"
          />
          <LeadItem
            title={intl.formatMessage({ id: "index.lead_2_title" })}
            body={[intl.formatMessage({ id: "index.lead_2_p1" })]}
            icon={jeepIcon}
            link="/jeepsafari/"
            price="55€"
          />
          <LeadItem
            title={intl.formatMessage({ id: "index.lead_3_title" })}
            body={[intl.formatMessage({ id: "index.lead_3_p1" })]}
            icon={mountainIcon}
            link="/for-groups/"
            price="25€"
          />
        </div>
      </section>

      <section className="wrapper container wrap">
        <h2 className="sectionTitle">
          {intl.formatMessage({ id: "index.trips_title" })}
        </h2>
        <div className="container trips">
          <TripItem
            title={intl.formatMessage({ id: "trip1.name" })}
            duration={intl.formatMessage({ id: "trip1.duration" })}
            img={trip1}
            link="/jeepsafari#trip1/"
            body={[
              intl.formatMessage({ id: "trip1.desc_p1" }),
              intl.formatMessage({ id: "trip1.desc_p2" }),
            ]}
          />
          <TripItem
            title={intl.formatMessage({ id: "trip2.name" })}
            duration={intl.formatMessage({ id: "trip2.duration" })}
            img={trip2}
            link="/jeepsafari#trip2/"
            body={[
              intl.formatMessage({ id: "trip2.desc_p1" }),
              intl.formatMessage({ id: "trip2.desc_p2" }),
            ]}
          />
        </div>
        <aside className="banners">
          <img
            className="banner"
            src={(intl.locale == "pl" && taExcelPl) || taExcelEn}
          />
          <p
            style={{ textAlign: "center", color: "#FC6621", fontWeight: "600" }}
          >
            Podziel się swoją opinią o nas!
          </p>
          {intl.locale == "pl" && (
            <div id="TA_selfserveprop377" class="TA_selfserveprop">
              <ul id="WNYnOivoLrc" class="TA_links xLEn2VP3sOYE">
                <li id="j0NQ2inzg" class="O4BAy5wD">
                  <a
                    target="_blank"
                    href="https://pl.tripadvisor.com/Attraction_Review-g189167-d8048937-Reviews-Dragon_Tree_Travel-Funchal_Madeira_Madeira_Islands.html"
                  >
                    <img
                      src="https://pl.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-11900-2.svg"
                      alt="TripAdvisor"
                    />
                  </a>
                </li>
              </ul>
            </div>
          )}
          {intl.locale == "pl" && (
            <script
              async
              src="https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=377&amp;locationId=8048937&amp;lang=pl&amp;rating=true&amp;nreviews=5&amp;writereviewlink=true&amp;popIdx=true&amp;iswide=false&amp;border=true&amp;display_version=2"
              data-loadtrk
              onload="this.loadtrk=true"
            ></script>
          )}
        </aside>

        <div className="container trips">
          <TripItem
            title={intl.formatMessage({ id: "trip3.name" })}
            duration={intl.formatMessage({ id: "trip3.duration" })}
            img={trip3}
            link="/jeepsafari#trip3/"
            body={[
              intl.formatMessage({ id: "trip3.desc_p1" }),
              intl.formatMessage({ id: "trip3.desc_p2" }),
            ]}
          />
          <TripItem
            title={intl.formatMessage({ id: "trip4.name" })}
            duration={intl.formatMessage({ id: "trip4.duration" })}
            img={trip4}
            link="/jeepsafari#trip4/"
            body={[
              intl.formatMessage({ id: "trip4.desc_p1" }),
              intl.formatMessage({ id: "trip4.desc_p2" }),
            ]}
          />
        </div>
        <div className="container trips">
          <TripItem
            title={intl.formatMessage({ id: "trip5.name" })}
            duration={intl.formatMessage({ id: "trip5.duration" })}
            img={trip5}
            link="/jeepsafari#trip5/"
            body={[intl.formatMessage({ id: "trip5.desc_p1" })]}
          />
          <TripItem
            title={intl.formatMessage({ id: "trip6.name" })}
            duration={intl.formatMessage({ id: "trip6.duration" })}
            img={trekking}
            link="/trekking/"
            body={[intl.formatMessage({ id: "trip6.desc_p1" })]}
          />
        </div>
      </section>
    </Layout>
  )
)

export default injectIntl(IndexPage)
