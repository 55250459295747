import React from 'react';
import { FormattedMessage } from "gatsby-plugin-intl";
import MoreLink from './moreLink';

import '../scss/tripItem.scss';

const TripItem = ({ intl, img, title, body, link, duration }) => {
	return (
		<div className="tripItem col col--2">
			<div className="tripImage">
				<img className="tripImage--img" src={img} />
			</div>
			<div className="tripDetails">
				<span className="duration">{duration}</span>
				<h3>{title}</h3>
				{body.map(paragraph => <p>{
				<FormattedMessage
				id={paragraph}
				defaultMessage={paragraph}
				values={{
					b: chunks => <b>{chunks}</b>
				}}
			/>
				}</p>)}
				<p><MoreLink to={link} /></p>
			</div>
		</div>
	)
}

export default TripItem
